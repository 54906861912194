import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

function TimedElement({ children, startDate, endDate }) {
  const [showElement, setShowElement] = useState(false)

  useEffect(() => {
    let now = Date.now()

    let start = Date.parse("1 Jan 1970 00:00:00 UTC")
    let end = Date.parse("31 Dec 2099 23:59:59 UTC")

    if (startDate) start = Date.parse(startDate)
    if (endDate) end = Date.parse(endDate)
    // start = Date.parse('25 Nov 2020 13:12:00 PST'); // Date for dev testing

    if (end < start) {
      throw "End date is less than start date"
    }

    if (now >= start && now < end) {
      setShowElement(true)
    }
  }, [])

  if (showElement) {
    return children
  } else {
    return null
  }
}

export default TimedElement

TimedElement.propTypes = {
  children: PropTypes.node.isRequired,
  startDate: PropTypes.string,
  endDate: PropTypes.string
}
